import axios from 'axios'
import store from '@/store'

const defaultEndpoint = '/people'

const defaultSuggestedUsers = {
  data: [],
  links: {
    prev: null,
    next: null,
  },
  meta: {
    path: null,
    per_page: 5,
  },
}

const state = {
  suggestedUsers: defaultSuggestedUsers,
  myFollowingUsers: {},
  myFollowersUsers: {},
  currentFollowingUsers: {},
  forceRecomputeCounter: 0,
  shallRefreshCounter: 0,
}

const getters = {
  suggestedUsers: (state) => state.suggestedUsers,
  myFollowingUsers: (state) => state.myFollowingUsers,
  myFollowersUsers: (state) => state.myFollowersUsers,
  currentFollowingUsers: (state) => state.currentFollowingUsers,
  forceRecomputeCounter: (state) => {
    return state.forceRecomputeCounter
  },
  shallRefreshCounter: (state) => {
    return state.shallRefreshCounter
  },
}

const actions = {
  async fetchPeople({ commit }, data) {
    let { payload, username } = data
    let endpoint = `${defaultEndpoint}`

    if (!_.isEmpty(username)) {
      endpoint = `/users/${username}${endpoint}`
    }

    let response = await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })

    if (response.data == null || response.data.data == null) {
      if (payload && payload.type === 'following' && payload.search == null) {
        commit('setFollowingUsers', { username, data: null })
      } else if (
        payload &&
        payload.type === 'followers' &&
        payload.search == null
      ) {
        commit('setFollowersUsers', { username, data: null })
      }

      return response
    }

    if (payload && payload.type === 'following' && payload.search == null) {
      commit('setFollowingUsers', { username, data: response.data })
    } else if (
      payload &&
      payload.type === 'followers' &&
      payload.search == null
    ) {
      commit('setFollowersUsers', { username, data: response.data })
    }

    return response
  },

  fetchSuggestedUsers({ commit }, data) {
    let { payload } = data

    return this._vm.$api
      .get(`${defaultEndpoint}/suggestions`, { params: payload })
      .then((response) => {
        commit('setSuggestedUsers', response.data)
        return response
      })
      .catch((error) => {
        commit('setSuggestedUsers', defaultSuggestedUsers)
      })
  },

  async removeFollower({ dispatch, commit }, form) {
    let payload = {}
    let endpoint = `${defaultEndpoint}/follower`

    if (form.username) {
      payload.username = form.username
    }

    let response = await this._vm.$api.delete(endpoint, { params: payload })

    if (response.data == null) {
      return response
    }

    store.commit('contacts/unsetContactSet', 'contacts')
    store.dispatch('notifications/analyseShallRefresh', payload.username)

    return response
  },

  async toggleFollowingRelation({ dispatch, commit }, form) {
    let { username } = form
    let response = await form.post(
      `${defaultEndpoint}/toggle-following-relation`
    )

    if (username && !form.errors.any()) {
      commit('setFollowingUsers', { username: null, data: null })
    }

    store.dispatch('notifications/analyseShallRefresh', username)

    return response
  },

  async contactRequest({ dispatch, commit }, form) {
    let { username } = form
    return form.post(`${defaultEndpoint}/contact-request`)
  },
}

const mutations = {
  setFollowingUsers(state, payload) {
    let { username, data } = payload
    let newData = null

    if (data != null) {
      newData = {
        data: data.data ? data.data : null,
        nextPageLink: data.links ? data.links.next : null,
      }
    }

    if (username == null) {
      state.myFollowingUsers = newData
    } else {
      state.currentFollowingUsers = newData
    }
  },

  setFollowersUsers(state, payload) {
    let { username, data } = payload
    let newData = null

    if (data != null) {
      newData = {
        data: data.data ? data.data : null,
        nextPageLink: data.links ? data.links.next : null,
      }
    }

    state.myFollowersUsers = newData
  },

  setSuggestedUsers(state, users) {
    state.suggestedUsers = users
  },
  incrementForceRecomputeCounter(state, value = 1) {
    state.forceRecomputeCounter += value
  },
  incrementShallRefreshCounter(state) {
    state.shallRefreshCounter++
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
