import axios from 'axios'
import store from '@/store'
import cookies from 'axios/lib/helpers/cookies'
import router from '@/router'

let defaultConfig = {
  version: 'v1',
  secure: true,
}

/**
 * @return {string}
 */
let ApiUrl = function (version = 'v1', secure = true) {
  let protocol = 'http'
  let hostname = process.env.VUE_APP_API_DOMAIN

  // Force https manually, otherwise use default
  if (secure) {
    protocol = 'https:'
  }

  return protocol + '//' + hostname + '/api/' + version
}

/**
 * @return {string}
 */
let AppUrl = function (secure = true) {
  let protocol = 'http'
  let hostname = process.env.VUE_APP_DOMAIN
  let port = window.location.port !== '' ? `:${window.location.port}` : ''

  // Force https manually, otherwise use default
  if (secure) {
    protocol = 'https:'
  }

  return protocol + '//' + hostname + port
}

let ResourceExists = async function fileExists(imgUrl) {
  if (!imgUrl) {
    return false
  }
  return new Promise((res) => {
    const image = new Image()
    image.crossOrigin = 'Anonymous'
    image.src = imgUrl

    image.onload = () => res(true)
    image.onerror = () => res(false)
  })
}

let DownloadResource = async function dowloadResource(imgUrl) {
  if (!imgUrl) {
    return false
  }

  const newTab = window.open(imgUrl, '_blank')
  newTab.onload = () => {
    const link = newTab.document.createElement('a')
    link.href = imgUrl
    link.download = filename
    link.click()
    URL.revokeObjectURL(imgUrl)
    newTab.close()
  }
}

/**
 * Generate Axios Instance with automatic baseUrl resolution.
 * Also enable withCredentials to allow cookies to be sent for Auth.
 *
 * @param version
 * @param secure
 * @returns {AxiosInstance}
 * @constructor
 */
let ApiFactory = function (version = 'v1', secure = false) {
  const instance = axios.create({
    baseURL: ApiUrl(version, secure),
    withCredentials: true,
    timeout: 15000,
  })

  instance.resourceExists = ResourceExists
  instance.downloadResource = DownloadResource

  // instance.interceptors.request.use(function (config) {
  //   let csrfToken = cookies.read(axios.defaults.xsrfCookieName)
  //   if (csrfToken && config.headers['X-Xsrf-Token'] == null) {
  //     config.headers['X-Xsrf-Token'] = csrfToken
  //   }
  //   return config
  // })

  instance.interceptors.response.use(
      (response) => {
        store.commit('app/DISPLAY_NETWORK_ERROR', false)
        store.commit('app/RESET_UNAUTHENTICATED_ERROR_COUNTER')
        store.commit('app/SET_PAGE_DID_LOAD', true)

        return response
      },
      (error) => {
        store.commit('app/SET_PAGE_DID_LOAD', true)
        if (!error.response || !error.response.status) {
          store.commit('app/DISPLAY_NETWORK_ERROR', true)
          // setTimeout(() => {
          //   if (process.env.NODE_ENV === 'production') {
          //     router.push({ name: 'no-internet-connection' })
          //   }
          // }, 50)
          return false
        } else {
          store.commit('app/DISPLAY_NETWORK_ERROR', false)
        }

        if (store.getters['app/unauthenticatedErrorCounter'] >= 3) {
          store.dispatch('auth/logout')
          console.error('401. Forcing logout')
        }

        if (
            error.response &&
            401 === error.response.status &&
            store.state.auth.user.loggedIn
        ) {
          store.commit('app/INCREASE_UNAUTHENTICATED_ERROR_COUNTER')
        }

        return Promise.reject(error)
      }
  )

  return instance
}

const Api = {
  install(Vue, options) {
    let config = Object.assign(defaultConfig, options)

    Vue.prototype.$appUrl = AppUrl(config.secure)
    Vue.prototype.$apiUrl = ApiUrl(config.version, config.secure)
    Vue.prototype.$api = ApiFactory(config.version, config.secure)
  },
}

export default Api
