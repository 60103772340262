import dayjs from 'dayjs'
import Form from '../../plugins/Form'
import router from '@/router'
import store from '@/store'
import * as Sentry from "@sentry/browser";

let emptyForm = new Form({})

export default {
  namespaced: true,
  state: {
    errors: null,
    browserToken: null,
    user: {
      id: null,
      loggedIn: null,
      registered: null,
      verified: null,
      blocked: null,
      fetchedAt: null,
      roles: null,
      expired: true,
      timezone: null,
      data: null,
      completeProfile: null,
      staticData: null,
      relations: null,
      invitationsLeft: null,
      advocatedInvitations: null,
    },
  },
  getters: {
    errors: (state) => (state.errors == null ? emptyForm.errors : state.errors),
    browserToken: (state) => state.browserToken,
    user: (state) => state.user,
    isLoggedIn: (state) => {
      return state.user.loggedIn === true && state.user.expired === false
    },
    blocked: (state) => {
      return state.user.blocked
    },
  },

  actions: {
    fetchUser({ commit }) {
      return this._vm.$api
        .get('/auth/user')
        .then((response) => {
          commit('setFetchedUser', response.data)
        })
        .catch((error) => {
          commit('setFetchedUser', null)
        })
    },

    updateUser({ commit, dispatch, state }) {
      let user = state.user

      if (user.fetchedAt !== null && user.fetchedAt instanceof dayjs) {
        user.expired = dayjs().isAfter(user.fetchedAt.add(60, 'minute'))
      }

      if (user.expired === true || user.fetchedAt === null) {
        return dispatch('fetchUser')
      }

      return true
    },

    async login({ dispatch, commit }, form) {
      await this._vm.$api.get('/sanctum/csrf-cookie')

      return form.submit('post', '/auth/login')
    },

    async register({ dispatch, commit }, form) {
      await this._vm.$api.get('/sanctum/csrf-cookie')

      return form.submit('post', '/auth/register')
    },

    async loginSocialMedia({ dispatch, commit }, form) {
      await this._vm.$api.get('/sanctum/csrf-cookie')

      return form.submit('post', `/auth/social-media/login`)
    },

    async registerSocialMedia({ dispatch, commit }, form) {
      await this._vm.$api.get('/sanctum/csrf-cookie')

      return form.submit('post', `/auth/social-media/register`)
    },

    async connectSocialMedia({ dispatch, commit }, form) {
      return form.submit('post', `/auth/social-media/connect`)
    },

    async disconnectSocialMedia({ dispatch, commit }, form) {
      return form.submit('post', `/auth/social-media/disconnect/`)
    },

    async logout({ dispatch, commit }) {
      await this._vm.$api.get('/sanctum/csrf-cookie')
      await this._vm.$api.post('/auth/logout').catch((error) => {})

      commit('setFetchedUser', null)
      return true
    },

    async verifyEmail({ dispatch, commit }, form) {
      return form.submit('post', '/auth/email/verification-notification')
    },

    async forgotPassword({ dispatch, commit }, form) {
      await this._vm.$api.get('/sanctum/csrf-cookie')

      return form.submit('post', '/auth/forgot-password')
    },

    async validateRegisterInvitation({ dispatch, commit }, form) {
      await this._vm.$api.get('/sanctum/csrf-cookie')

      return form.submit('post', '/auth/validate-register-invitation')
    },

    async resetPassword({ dispatch, commit }, form) {
      await this._vm.$api.get('/sanctum/csrf-cookie')

      return form.submit('post', '/auth/reset-password')
    },

    async changePassword({ dispatch, commit }, form) {
      return form.submit('post', '/auth/user/password')
    },
  },

  mutations: {
    async setFetchedUser(state, user) {
      let userState = {
        id: null,
        loggedIn: false,
        registered: null,
        verified: null,
        blocked: null,
        fetchedAt: null,
        roles: null,
        data: {
          first_name: null,
          last_name: null,
          name: null,
          username: null,
          email: null,
          phone_number: null,
          phone_number_country_code: null,
          verified: null,
          blocked: null,
          has_social_media_connections: null,
          google_email: null,
          apple_email: null,
          avatar: null,
          cover: null,
          session_activity_id: null,
          session_settings: null,
          timezone: null,
        },
        completeProfile: {},
        staticData: {},
        relations: {},
        invitationsLeft: 0,
        advocatedInvitations: 0,
      }

      if (user && !_.isEmpty(user.data)) {
        userState.id = user.data.id
        userState.loggedIn = true
        userState.registered = true
        userState.verified = user.data.attributes.verified
        userState.blocked = user.data.attributes.blocked
        userState.timezone = user.data.attributes.timezone
        userState.expired = false
        userState.data = { ...userState.data, ...user.data.attributes }
        userState.completeProfile = { ...user.data.complete_profile }
        userState.staticData = { ...userState.staticData, ...user.data.static }
        userState.relations = { ...userState.relations, ...user.data.relations }
        userState.invitationsLeft = user.data.relations.stats.invitations_left
        userState.advocatedInvitations =
          user.data.relations.stats.advocated_invitations

        if (FS != null) {
          FS('setIdentity', {
            uid: userState.id,
            properties: {
              displayName: userState.data.name,
              email: userState.email,
            },
          })
        }

        if (Sentry != null) {
          Sentry.setUser({  id: userState.id, email: userState.email });
        }
      }

      if (
        process.env.VUE_APP_WEBSOCKET_ENABLED === 'true' &&
        !state.user.loggedIn &&
        userState.loggedIn
      ) {
        setWindowMainEcho(userState.data.username)

        Echo.private(`user.${userState.data.username}`).listen(
          '.notification',
          (event) => {
            if (event.type === 'chat') {
              store.dispatch('conversations/incrementNewMessagesCount')
              store.dispatch('conversations/checkConversationsActivity', event)
            } else {
              store.dispatch('notifications/incrementNewNotificationsCount')
            }
          }
        )

        Echo.private(`user.${userState.data.username}`).notification(
          (notification) => {
            store.dispatch('notifications/incrementNewNotificationsCount')
          }
        )
      }

      userState.fetchedAt = dayjs()
      let shouldRedirect =
        state.user.loggedIn &&
        !userState.loggedIn &&
        router.currentRoute.meta.hasOwnProperty('middleware') &&
        router.currentRoute.meta.middleware.includes('auth')

      state.user = { ...userState }

      if (shouldRedirect) {
        // loggedIn null is changing the entire layout of the app to LoadingLayout
        state.user.loggedIn = null
        window.loggedInUser = null
        router.push({ name: 'welcome' })
        return null
      }

      window.loggedInUser = userState.data.username

      let currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      if (
        currentTimezone &&
        userState.timezone !== currentTimezone &&
        state.user.loggedIn
      ) {
        let form = new Form({ timezone: currentTimezone }, this._vm.$api, store)
        store.dispatch('settings/updateSessionTimezone', form)
      }

      let browserToken = this._vm.$OneSignal
        ? this._vm.$OneSignal.User.PushSubscription.token
        : null

      if (browserToken && state.user.loggedIn) {
        let data = {
          enabled: true,
          device_name: `Android`,
          binding_type: 'android_browser',
          binding_address: browserToken,
        }

        store.dispatch('settings/updateSessionPushNotificationSetting', data)

        this._vm.$OneSignal.User.PushSubscription.optIn()
      }

      // if (
      //   this._vm.$capacitor.deviceInfo.platform === 'android' &&
      //   state.user.loggedIn &&
      //   userState.data.session_settings &&
      //   !userState.data.session_settings.has_binding_address
      // ) {
      //   await this._vm.$capacitor.registerNotifications()
      // }
    },

    setBrowserToken(state, token) {
      state.browserToken = token
    },

    setUser(state, user) {
      state.user = user
    },

    setErrors(state, errors) {
      state.errors = errors
    },
  },
}

function setWindowMainEcho(username) {
  window.initMainEcho = () => {
    if (Echo.socketId() != null) {
      let error = window.letErrorInMainEcho.err
      return
    }

    store.dispatch('notifications/fetchNotifications', {})

    if (
      router.currentRoute.name === 'agendum-details' ||
      router.currentRoute.name === 'chat-details'
    ) {
      let id = router.currentRoute.params.id
      let payload = { agendum_id: id }
      let request = { payload }

      store.commit('app/TOGGLE_LOADING_ANIMATION', true)
      store.dispatch('agendums/resetState', id)
      store.dispatch('conversations/resetState', id)
      store.dispatch('agendumActivities/resetState', id)
      store.dispatch('agendumNotes/resetState', id)
      store.dispatch('agendumMessages/resetState', id)

      store.dispatch('agendums/fetchAgendum', { payload, id })
      store.dispatch('agendums/fetchMembership', {
        payload,
        agendumId: id,
      })
      store.dispatch('agendumMessages/fetchMessages', request)
      store.dispatch('agendumNotes/fetchNotes', request)
      store.dispatch('agendumActivities/fetchActivities', request)
      store.dispatch('agendumAttachments/fetchAttachments', {
        payload: { ...payload, type: 'photo' },
      })
      store
        .dispatch('agendumAttachments/fetchAttachments', {
          payload: { ...payload, type: 'poll' },
        })
        .then(() => store.commit('app/TOGGLE_LOADING_ANIMATION', false))
    } else if (router.currentRoute.name === 'chat') {
      let payload = {}
      let request = { payload: payload, incrementForceRecomputeCounter: true }

      store.dispatch('conversations/resetState')
      store.dispatch('agendums/resetState')
      store.dispatch('agendumActivities/resetState')
      store.dispatch('agendumNotes/resetState')
      store.dispatch('agendumMessages/resetState')

      store.dispatch('conversations/fetchConversations', request)
    } else {
      store.dispatch('agendums/resetState')
      store.dispatch('agendumActivities/resetState')
      store.dispatch('agendumNotes/resetState')
      store.dispatch('agendumMessages/resetState')
    }

    Echo.private(`user.${username}`).listen('.notification', (event) => {
      if (event.type === 'chat') {
        store.dispatch('conversations/incrementNewMessagesCount')
        store.dispatch('conversations/checkConversationsActivity', event)
      } else {
        store.dispatch('notifications/incrementNewNotificationsCount')
      }
    })

    Echo.private(`user.${username}`).notification((notification) => {
      store.dispatch('notifications/incrementNewNotificationsCount')
    })
  }
}
