import store from '@/store'

const defaultEndpoint = '/invitations'

const state = {
  invitations: {},
  shallFetch: true,
  shallRefreshCounter: 0,
}

const getters = {
  invitations:
      (state) =>
          (data = null) => {
            return state.invitations
          },
  shallFetch: (state) => {
    return state.shallFetch
  },

  shallRefreshCounter: (state) => {
    return state.shallRefreshCounter
  },
}

const actions = {
  async fetchInvitations({ commit }, data) {
    let { payload } = data
    let endpoint = `${defaultEndpoint}`

    return await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })
  },

  async acceptInvitation({ dispatch, commit }, form) {
    await form.post(`${defaultEndpoint}/${form.id}/accept`)
    let response = form._response

    if (!form.errors.any() && response && response.status === 'success') {
      store.dispatch('notifications/decrementPendingNotificationsCount')
    }

    return response
  },

  async ignoreInvitation({ dispatch, commit }, form) {
    await form.post(`${defaultEndpoint}/${form.id}/ignore`)
    let response = form._response

    if (!form.errors.any() && response && response.status === 'success') {
      store.dispatch('notifications/decrementPendingNotificationsCount')
    }

    return response
  },

  async declineInvitation({ dispatch, commit }, form) {
    await form.post(`${defaultEndpoint}/${form.id}/decline`)
    let response = form._response

    if (!form.errors.any() && response && response.status === 'success') {
      store.dispatch('notifications/decrementPendingNotificationsCount')
    }

    return response
  },

  createRegisterInvitation({ dispatch, commit }, form) {
    return form.post(`${defaultEndpoint}/`)
  },
}

const mutations = {
  setInvitations(state, payload) {
    let { data } = payload
    let newData = null

    if (data != null) {
      newData = {
        data: data.data ? data.data : null,
        nextPageLink: data.links ? data.links.next : null,
      }
    }

    state.invitations = newData
  },

  setShallFetch(state, value) {
    state.shallFetch = value
  },

  incrementShallRefreshCounter(state) {
    state.shallRefreshCounter++
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
