import store from '@/store'
import router from '@/router'
import { commit } from 'lodash/seq'

const defaultEndpoint = '/agendums'

const state = {
  agendumList: {},
  agendumNextPageLink: null,
  agendumFiltersApplied: null,
  filterSelectedDate: null,

  agendumListScrollIndex: {},
  shallFetch: true,

  shallRefreshCounter: 0,

  agendums: {},
  agendum: null,

  memberships: {},
  membership: null,

  query: null,
  hash: null,
}

const getters = {
  shallFetch: (state) => {
    return state.shallFetch
  },

  shallRefreshCounter: (state) => {
    return state.shallRefreshCounter
  },

  agendumListScrollIndex: (state) => {
    return state.agendumListScrollIndex
  },

  agendumList: (state) => {
    return state.agendumList ? state.agendumList : null
  },

  agendumNextPageLink: (state) => {
    return state.agendumNextPageLink ? state.agendumNextPageLink : null
  },

  agendumFiltersApplied: (state) => {
    return state.agendumFiltersApplied ? state.agendumFiltersApplied : null
  },

  filterSelectedDate: (state) => {
    return state.filterSelectedDate ? state.filterSelectedDate : null
  },

  query: (state) => {
    return state.query ? state.query : null
  },

  hash: (state) => {
    return state.hash ? state.hash : null
  },

  agendum: (state) => (id) => {
    return state.agendums[id] ? state.agendums[id] : null
  },

  membership: (state) => (id) => {
    return state.memberships[id] ? state.memberships[id] : null
  },

  agendaItems: (state) => (id) => {
    return state.agendums[id] ? state.agendums[id].relations.items : null
  },
}

const actions = {
  async resetState({ dispatch, commit }, exceptId = null) {
    commit('init', exceptId)
  },

  async fetchAgendums({ commit }, data) {
    let { payload, username, id } = data
    let endpoint = `${defaultEndpoint}`

    if (!_.isEmpty(username)) {
      endpoint = `/users/${username}${endpoint}`
    }

    return await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })
  },

  async fetchTimeline({ commit }, data) {
    let { payload, username } = data
    let endpoint = `${defaultEndpoint}/timeline`
    let dateType = payload.dateType ? payload.dateType : 'all'

    if (!_.isEmpty(username)) {
      endpoint = `/users/${username}${endpoint}`
    }

    return await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })
  },

  async fetchAgendum({ commit }, data) {
    let { payload, id } = data
    let endpoint = `${defaultEndpoint}/${id}`

    let response = await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })

    if (response.data == null || response.data.data == null) {
      commit('setAgendum', { data: null, id })
      return response
    }

    commit('setAgendum', { data: response.data.data, id })
    return response
  },

  async fetchAgendumPreview({ commit }, data) {
    let { payload, id } = data
    let endpoint = `${defaultEndpoint}/${id}/preview`

    return await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })
  },

  async fetchMembership({ commit }, data) {
    let { payload, agendumId } = data
    let endpoint = `${defaultEndpoint}/${agendumId}/membership`

    let response = await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })

    if (response.data == null || response.data.data == null) {
      commit('setMembership', { data: null, agendumId })
      return response
    }

    commit('setMembership', { data: response.data.data, agendumId })
    return response
  },

  async fetchInvitations({ commit }, data) {
    let { payload, id } = data
    let endpoint = `${defaultEndpoint}/${id}/invitations`

    return await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })
  },

  async fetchFeaturedPhotos({ commit }, data) {
    let { payload, id } = data
    let endpoint = `${defaultEndpoint}/${id}/featured-photos`

    return await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })
  },

  async fetchPeople({ commit }, data) {
    let { payload, id } = data
    let endpoint = `${defaultEndpoint}/${id}/people`

    return await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })
  },

  async fetchRequestsToJoin({ commit }, data) {
    let { payload, id } = data
    payload.type = 'join'
    let endpoint = `${defaultEndpoint}/${id}/requests`

    return await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })
  },

  async fetchMembers({ commit }, data) {
    let { payload, id } = data
    let endpoint = `${defaultEndpoint}/${id}/members`

    return await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })
  },

  createAgendum({ dispatch, commit }, form) {
    return form.post(defaultEndpoint)
  },

  updateAgendum({ dispatch, commit }, form) {
    return form.post(`${defaultEndpoint}/${form.id}`)
  },

  updateSettings({ dispatch, commit }, form) {
    return form.post(`${defaultEndpoint}/${form.id}/settings`)
  },

  inviteMembers({ dispatch, commit }, form) {
    let { id } = form
    return form.post(`${defaultEndpoint}/${id}/invite`)
  },

  actionUpdateOpenInvitation({ dispatch, commit }, form) {
    let { id } = form
    return form.post(`${defaultEndpoint}/${id}/open-invitation`)
  },

  updateMember({ dispatch, commit }, form) {
    let { agendum_id } = form
    return form.post(`${defaultEndpoint}/${agendum_id}/member`)
  },

  updateInvitation({ dispatch, commit }, form) {
    let { agendum_id } = form
    return form.post(`${defaultEndpoint}/${agendum_id}/invitation`)
  },

  async setCoverTemplate({ dispatch, commit }, form) {
    form.originalData.cover_template = null
    form.record({ ...form.data(), ...{ cover_template: form.name } })

    return form.post(`${defaultEndpoint}/${form.params.id}/cover-photo`)
  },

  async shareAgendum({ dispatch, commit }, form) {
    let { id } = form
    return form.post(`${defaultEndpoint}/${id}/share`)
  },

  async duplicateAgendum({ dispatch, commit }, form) {
    let { id } = form
    await form.post(`${defaultEndpoint}/${id}/duplicate`)

    let response = form._response

    if (!form.errors.any() && response) {
    }

    return response
  },

  async joinAgendum({ dispatch, commit }, form) {
    let { id } = form
    return form.post(`${defaultEndpoint}/${id}/join`)
  },

  async actionCreateAgendumRequest({ dispatch, commit }, form) {
    let { id } = form
    return form.post(`${defaultEndpoint}/${id}/request`)
  },

  async exitAgendum({ dispatch, commit }, form) {
    let { id } = form
    await form.post(`${defaultEndpoint}/${id}/exit`)

    let response = form._response

    if (!form.errors.any() && response) {
      commit('setAgendum', { data: null, id })
      commit('setMembership', { data: null, id })
      commit('setAgendumInList', { data: null, id })
      store.commit('feed/setAgendumInList', { data: null, id })
      store.commit('conversations/setConversationInList', { data: null, id })
    }

    return response
  },

  spectateAgendum({ dispatch, commit }, form) {
    return form.post(`${defaultEndpoint}/${form.id}/spectate`)
  },

  async deleteAgendum({ dispatch, commit }, form) {
    let { id } = form
    await form.delete(`${defaultEndpoint}/${id}`)

    let response = form._response

    if (!form.errors.any() && response) {
      commit('setAgendum', { data: null, id })
      commit('setMembership', { data: null, id })
      commit('setAgendumInList', { data: null, id })
      store.commit('feed/setAgendumInList', { data: null, id })
      store.commit('conversations/setConversationInList', { data: null, id })
    }

    return response
  },

  deleteMember({ dispatch, commit }, form) {
    let { agendum_id } = form
    return form.delete(`${defaultEndpoint}/${agendum_id}/member`)
  },

  stopListeningForUpdates({ dispatch, commit }, id) {
    if (process.env.VUE_APP_WEBSOCKET_ENABLED === 'true') {
      Echo.leave(`agendum.${id}`)
    }
  },

  checkDetailsActivity({ dispatch, commit }, event) {
    if (process.env.VUE_APP_WEBSOCKET_ENABLED === 'true') {
      let authUser = store.getters['auth/user']

      if (event.action === 'created' || event.action === 'updated') {
        commit('setAgendum', {
          data: event.data,
          id: event.data.id,
        })
      } else if (event.action === 'items_updated') {
        let data = event.data
        data.relations = { ...data.relations, items: null }

        commit('setAgendum', {
          data: data,
          id: event.data.id,
        })
      } else if (event.action === 'deleted') {
        commit('setAgendum', {
          data: null,
          id: event.data.id,
        })

        commit('setMembership', {
          data: null,
          agendumId: event.data.id,
        })

        store.commit('agendumActivities/setActivities', {
          data: null,
          agendumId: event.data.id,
        })

        store.commit('agendumNotes/setNotes', {
          data: null,
          agendumId: event.data.id,
        })

        store.commit('agendumMessages/setMessages', {
          data: null,
          agendumId: event.data.id,
        })

        router.push({ name: 'home' })
        return
      }

      if (!_.isEmpty(event.activity)) {
        store.commit('agendumActivities/setActivity', {
          activity: !_.isEmpty(event.activity.attributes)
            ? event.activity
            : null,
          activityId: event.activity.id,
          agendumId: event.data.id,
        })
      }

      if (event.subjectId === authUser.data.username) {
        setTimeout(() => {
          store.dispatch('agendums/fetchMembership', {
            payload: {},
            agendumId: event.data.id,
          })
        }, 1000)
      }
    }
  },

  checkMembershipActivity({ dispatch, commit }, event) {
    if (process.env.VUE_APP_WEBSOCKET_ENABLED === 'true') {
      let authUser = store.getters['auth/user']

      if (!_.isEmpty(event.activity)) {
        store.commit('agendumActivities/setActivity', {
          activity: !_.isEmpty(event.activity.attributes)
            ? event.activity
            : null,
          activityId: event.activity.id,
          agendumId: event.data.id,
        })
      }

      if (
        event.data.username === authUser.data.username ||
        event.data.username === 'all'
      ) {
        setTimeout(() => {
          store.dispatch('agendums/fetchMembership', {
            payload: {},
            agendumId: event.data.id,
          })
        }, 1000)
      }
    }
  },
}

const mutations = {
  setAgendumList(state, data) {
    state.agendumList = !_.isEmpty(data) ? [...data] : null

    if (data == null) {
      state.agendumNextPageLink = null
      state.agendumFiltersApplied = null
    }
  },

  setFilterSelectedDate(state, data) {
    state.filterSelectedDate = data
  },

  setAgendumNextPageLink(state, data) {
    state.agendumNextPageLink = data
  },

  setAgendumFiltersApplied(state, data) {
    state.agendumFiltersApplied = data
  },

  setAgendumListScrollIndex(state, index) {
    state.agendumListScrollIndex = index
  },

  setShallFetch(state, value) {
    state.shallFetch = value
  },

  setQuery(state, value) {
    state.query = value
  },

  setHash(state, value) {
    state.hash = value
  },

  setAgendumInList(state, payload) {
    let { data, id } = payload
    let agendum = null
    if (_.isEmpty(state.agendumList)) {
      return null
    }

    let agendums = [...state.agendumList]

    if (data) {
      state.agendumList = [
        ...agendums.map((item) => {
          if (item.id === id) {
            return data
          }
          return item
        }),
      ]
    } else {
      state.agendumList = [...agendums.filter((item) => item.id !== id)]
    }
  },

  setAgendum(state, payload) {
    let { data, id } = payload
    let agendum = null
    let agendums = { ...state.agendums }

    if (data == null) {
      if (!_.isEmpty(agendums[id])) {
        store.dispatch('agendums/stopListeningForUpdates', id)

        if (!_.isEmpty(state.agendums[id].relations.parent)) {
          mutations.setAgendaItems(state, {
            id: state.agendums[id].relations.parent.id,
            items: null,
          })
        }
        delete state.agendums[id]
      }
      return
    }

    if (_.isEmpty(agendums[id])) {
      agendum = { ...data }
      agendum.relations.items = null
    } else {
      agendum = { ...agendums[id] }

      if (agendum.timestamp < data.timestamp) {
        if (!_.isEmpty(data.attributes)) {
          agendum.attributes = { ...data.attributes }
        }

        if (!_.isEmpty(data.relations)) {
          for (let [key, value] of Object.entries(agendum.relations)) {
            if (data.relations[key] != null) {
              agendum.relations[key] = data.relations[key]
            }
          }
        }
      }
    }

    if (!_.isEmpty(agendum.relations.parent)) {
      mutations.setAgendaItems(state, {
        id: agendum.relations.parent.id,
        items: null,
      })
    }

    agendums[id] = agendum
    state.agendums = agendums
  },

  setAgendumNewMessages(state, payload) {
    let { data, id, value } = payload
    let agendum = null
    let agendums = { ...state.agendums }

    if (!_.isEmpty(agendums[id])) {
      agendum = { ...agendums[id] }
      if (value) {
        agendum.relations.stats.new_messages = value
      } else {
        agendum.relations.stats.new_messages =
          1 + parseInt(agendum.relations.stats.new_messages)
      }
      agendums[id] = agendum
    }

    state.agendums = agendums
  },

  setAgendaItems(state, payload) {
    let { items, id } = payload

    if (_.isEmpty(state.agendums) || _.isEmpty(state.agendums[id])) {
      return false
    }

    if (items == null) {
      state.agendums[id].relations.items = null
      return
    }

    state.agendums[id].relations.items = { ...items }
  },

  setMembership(state, payload) {
    let { data, agendumId } = payload
    let membership = null

    if (data != null) {
      membership = { ...data }
    }

    let memberships = { ...state.memberships }
    memberships[agendumId] = membership
    state.memberships = memberships
  },

  init(state, exceptId) {
    let savedAgendumState = null
    let savedMembershipState = null

    if (exceptId) {
      savedAgendumState =
        state.agendums && !_.isEmpty(state.agendums[exceptId])
          ? state.agendums[exceptId]
          : null
      savedMembershipState =
        state.memberships && !_.isEmpty(state.memberships[exceptId])
          ? state.memberships[exceptId]
          : null
    }
    state.agendums = {}
    state.memberships = {}
    state.agendum = null
    state.membership = null

    if (savedAgendumState) {
      state.agendums[exceptId] = savedAgendumState
    }

    if (savedMembershipState) {
      state.memberships[exceptId] = savedMembershipState
    }
  },
  incrementShallRefreshCounter(state) {
    state.shallRefreshCounter++
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
