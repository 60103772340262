import { getLastRouteFullPath } from '@/router';

export const nativeScrollBar = {
  activated() {
    if (
      this.$refs.perfectScrollbarComponent &&
      this.$refs.perfectScrollbarComponent.$el == null
    ) {
      if (this.perfectScrollbarComponentEl === 'self') {
        this.$refs.perfectScrollbarComponent.$el =
          this.$refs.perfectScrollbarComponent

        this.$refs.perfectScrollbarComponent.$el.addEventListener(
          'scroll',
          this.handleScroll
        )

        document.body.style.overflow = 'hidden'
      } else {
        this.$refs.perfectScrollbarComponent.$el = document.documentElement
      }
    }

     if (this.$route.meta.isBackNavigation && window.routeHistoryPositionStack[this.$route.fullPath] !== null) {
       this.updateScrollPosition(window.routeHistoryPositionStack[this.$route.fullPath])
     }

    window.addEventListener('scroll', this.handleWindowScroll)
  },

  deactivated() {
    if (this.$refs.perfectScrollbarComponent && this.$refs.perfectScrollbarComponent.$el) {
      window.routeHistoryPositionStack[getLastRouteFullPath()] = this.$refs.perfectScrollbarComponent.$el.scrollTop
    }

    if (this.perfectScrollbarComponentEl === 'self') {
      document.body.style.overflow = 'inherit'
    }
  },

  data() {
    return {
      documentEventListener: false,
      bottomOfWindow: false,
      bottomOfWindowThreshold: 300,
      topOfWindow: false,
      pullToRefresh: false,
      scrollingUp: null,
      scrollingDown: null,
      nextPageLink: null,
      perfectScrollbarComponentEl: 'document',
      prevScrollPosition: 0,
      initPrevScroll: false,
      lockScrollingDown: false,
      loadMoreDirection: 'bottom',
    }
  },

  methods: {
    handleScroll: window._.throttle(function (event = null) {
      if (
        this.$refs.perfectScrollbarComponent == null ||
        this.$refs.perfectScrollbarComponent.$el == null
      )
        return

      this.handleScrollDirection()

      this.bottomOfWindow =
        this.$refs.perfectScrollbarComponent.$el.scrollTop +
          window.innerHeight >=
        this.$refs.perfectScrollbarComponent.$el.scrollHeight -
          this.bottomOfWindowThreshold

      this.topOfWindow =
        this.$refs.perfectScrollbarComponent.$el.scrollTop === 0

      if (this.perfectScrollbarComponentEl === 'self') {
        let boxedPullToRefresh = this.getPullToRefreshBoxedElement()

        if (this.$refs.perfectScrollbarComponent.$el.scrollTop <= -100) {
          boxedPullToRefresh.classList.remove('hidden')
          this.reloadPage()
        } else {
          boxedPullToRefresh.classList.add('hidden')
          this.pullToRefresh = false
        }
      }

      this.onScroll(event)
    }, 300),

    handleWindowScroll(event = null) {
      if (document.getElementById('pull-to-refresh')) {
        if (window.scrollY <= -100) {
          document.getElementById('pull-to-refresh').classList.remove('hidden')
          this.reloadPage()
        } else {
          document.getElementById('pull-to-refresh').classList.add('hidden')
          this.pullToRefresh = false
        }
      }

      if (
        this.perfectScrollbarComponentEl === 'document' &&
        this.$refs.perfectScrollbarComponent
      ) {
        this.handleScroll(event)
      } else {
        this.onScroll(event)
      }

      this.onWindowScroll(event)
    },

    getPullToRefreshBoxedElement() {
      let element = document.getElementById('boxed-pull-to-refresh')
      if (element != null) {
        return element
      }

      let pullToRefresh = document.getElementById('pull-to-refresh')
      let boxedPullToRefresh = pullToRefresh.cloneNode(true)
      boxedPullToRefresh.id = 'boxed-pull-to-refresh'
      this.$refs.perfectScrollbarComponent.$el.prepend(boxedPullToRefresh)

      return boxedPullToRefresh
    },

    onScroll(event = null) {
      // this can be overridden by the parent component
    },

    onWindowScroll(event = null) {
      // this can be overridden by the parent component
    },

    reloadPage: window._.throttle(function () {
      if (!this.pullToRefresh) {
        this.pullToRefresh = true
        return
      }

      this.loading = true
      window.location.reload()
      this.pullToRefresh = false
    }, 300),

    updateScrollPosition(scrollIndex = this.agendumListScrollIndex) {
      if (
        scrollIndex != null &&
        this.$refs.perfectScrollbarComponent != null &&
        this.$refs.perfectScrollbarComponent.$el != null
      ) {
        this.$refs.perfectScrollbarComponent.$el.scrollTo(
          0,
          scrollIndex
        )
      }
    },

    scrollDown(index = 1000) {
      if (
        this.$refs.perfectScrollbarComponent &&
        this.$refs.perfectScrollbarComponent.$el
      ) {
        this.$refs.perfectScrollbarComponent.$el.scrollTo(
          0,
          this.$refs.perfectScrollbarComponent.$el.scrollTop + index
        )
      }
    },

    scrollTop() {
      if (
        this.$refs.perfectScrollbarComponent &&
        this.$refs.perfectScrollbarComponent.$el
      ) {
        this.$refs.perfectScrollbarComponent.$el.scrollTo(0, 0)
      }
    },

    scrollBottom() {
      if (
        this.$refs.perfectScrollbarComponent &&
        this.$refs.perfectScrollbarComponent.$el
      ) {
        this.$refs.perfectScrollbarComponent.$el.scrollTo(
          0,
          this.$refs.perfectScrollbarComponent.$el.scrollHeight
        )
      }
    },

    handleScrollDirection() {
      if (!this.initPrevScroll) {
        this.prevScrollPosition =
          this.$refs.perfectScrollbarComponent.$el.scrollTop
        this.initPrevScroll = true
        return
      }

      let scrollingDown = this.scrollingDown
      let scrollingUp = this.scrollingUp

      if (
        this.$refs.perfectScrollbarComponent.$el.scrollTop >=
        this.prevScrollPosition
      ) {
        scrollingDown = true
        scrollingUp = false
      } else if (
        this.$refs.perfectScrollbarComponent.$el.scrollTop <=
        this.prevScrollPosition
      ) {
        scrollingDown = false
        scrollingUp = true
      }

      this.scrollingDown = scrollingDown
      this.scrollingUp = scrollingUp
      this.prevScrollPosition =
        this.$refs.perfectScrollbarComponent.$el.scrollTop
    },
  },

  watch: {
    bottomOfWindow(to, from) {
      if (this.loadMoreDirection === 'bottom') {
        this.shallLoadMore = to
      }
    },

    topOfWindow(to, from) {
      if (to) {
        this.bottomOfWindow = false
      }
    },

    scrollingUp(to, from) {},
  },
}

export const _ = null
