export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/pages/home/Feed.vue'),
    meta: {
      pageTitle: 'Feed',
      middleware: ['auth', 'verified'],
      keepAlive: true,
    },
  },
  {
    path: '/feed',
    name: 'news-feed',
    component: () => import('@/views/pages/home/Feed.vue'),
    meta: {
      pageTitle: 'News Feed',
      middleware: ['auth', 'verified'],
      keepAlive: true,
    },
  },
  {
    path: '/invite',
    name: 'invite',
    component: () => import('@/views/pages/home/Feed.vue'),
    meta: {
      pageTitle: 'Invite',
      middleware: ['auth', 'verified'],
      keepAlive: true,
    },
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('@/views/pages/home/Feed.vue'),
    meta: {
      pageTitle: 'Info',
      middleware: ['auth', 'verified'],
      keepAlive: true,
    },
  },
  {
    path: '/create-agendum',
    name: 'create-agendum',
    component: () => import('@/views/pages/agendums/Agendums.vue'),
    meta: {
      pageTitle: 'Create Agendum',
      middleware: ['auth', 'verified'],
      keepAlive: true,
    },
  },
  {
    path: '/agendums',
    name: 'agendums',
    component: () => import('@/views/pages/agendums/Agendums.vue'),
    props: (route) => ({
      parentId: !_.isEmpty(route.query) ? route.query.parentId : null,
      agendumId: !_.isEmpty(route.query) ? route.query.agendumId : null,
      attachmentId: !_.isEmpty(route.query) ? route.query.attachmentId : null,
      shareCapture: !_.isEmpty(route.query) ? route.query.shareCapture : null,
    }),
    meta: {
      pageTitle: 'Agendums',
      middleware: ['auth', 'verified'],
      keepAlive: true,
    },
  },
  {
    path: '/users/:username/agendums',
    name: 'user-agendums',
    component: () => import('@/views/pages/agendums/Agendums.vue'),
    props: (route) => ({
      parentId: !_.isEmpty(route.query) ? route.query.parentId : null,
      agendumId: !_.isEmpty(route.query) ? route.query.agendumId : null,
      attachmentId: !_.isEmpty(route.query) ? route.query.attachmentId : null,
    }),
    meta: {
      middleware: ['auth', 'verified'],
      pageTitle: "User's Agendums",
      breadcrumb: [
        {
          text: "User's Profile",
          to: { name: 'user-profile' },
          active: false,
        },
        {
          text: 'Agendums',
          active: true,
        },
      ],
      keepAlive: true,
    },
  },
  {
    path: '/collections',
    name: 'collections',
    component: () => import('@/views/pages/collections/List.vue'),
    meta: {
      pageTitle: 'Collections',
      middleware: ['auth', 'verified'],
      breadcrumb: [
        {
          text: 'Agendums',
          to: { name: 'agendums' },
          active: false,
        },
        {
          text: 'Collections',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/:username/collections',
    name: 'user-collections',
    component: () => import('@/views/pages/collections/List.vue'),
    props: true,
    meta: {
      middleware: ['auth', 'verified'],
      pageTitle: "User's Collections",
      breadcrumb: [
        {
          text: "User's Profile",
          to: { name: 'user-profile' },
          active: false,
        },
        {
          text: 'Collections',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/pages/settings/Settings.vue'),
    meta: {
      middleware: ['auth', 'verified'],
      pageTitle: 'Settings',
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/help-center',
    name: 'help-center',
    component: () => import('@/views/pages/help-center/HelpCenter.vue'),
    meta: {
      middleware: ['auth', 'verified'],
      pageTitle: 'Help Center',
      breadcrumb: [
        {
          text: 'Help Center',
          active: true,
        },
      ],
    },
  },
  {
    path: '/help-center/terms-of-use',
    name: 'terms-of-use',
    component: () =>
      import('@/views/pages/help-center/terms-and-policies/TermsOfUse.vue'),
    meta: {
      // middleware: ['auth', 'verified'],
      pageTitle: 'Terms of Use',
      breadcrumb: [
        {
          text: 'Help Center',
          to: { name: 'help-center' },
        },
        {
          text: 'Terms of Use',
          active: true,
        },
      ],
    },
  },
  {
    path: '/help-center/community-standards',
    name: 'community-standards',
    component: () =>
      import(
        '@/views/pages/help-center/terms-and-policies/CommunityStandards.vue'
      ),
    meta: {
      // middleware: ['auth', 'verified'],
      pageTitle: 'Community Standards',
      breadcrumb: [
        {
          text: 'Help Center',
          to: { name: 'help-center' },
        },
        {
          text: 'Community Standards',
          active: true,
        },
      ],
    },
  },
  {
    path: '/help-center/music-guidelines',
    name: 'music-guidelines',
    component: () =>
      import(
        '@/views/pages/help-center/terms-and-policies/MusicGuidelines.vue'
      ),
    meta: {
      // middleware: ['auth', 'verified'],
      pageTitle: 'Music Guidelines',
      breadcrumb: [
        {
          text: 'Help Center',
          to: { name: 'help-center' },
        },
        {
          text: 'Music Guidelines',
          active: true,
        },
      ],
    },
  },
  {
    path: '/help-center/privacy-policy',
    name: 'privacy-policy',
    component: () =>
      import('@/views/pages/help-center/terms-and-policies/PrivacyPolicy.vue'),
    meta: {
      // middleware: ['auth', 'verified'],
      pageTitle: 'Privacy Policy',
      breadcrumb: [
        {
          text: 'Help Center',
          to: { name: 'help-center' },
        },
        {
          text: 'Privacy Policy',
          active: true,
        },
      ],
    },
  },
  {
    path: '/help-center/cookie-policy',
    name: 'cookie-policy',
    component: () =>
      import('@/views/pages/help-center/terms-and-policies/CookiePolicy.vue'),
    meta: {
      // middleware: ['auth', 'verified'],
      pageTitle: 'Cookie Policy',
      breadcrumb: [
        {
          text: 'Help Center',
          to: { name: 'help-center' },
        },
        {
          text: 'Cookie Policy',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/:username/people',
    name: 'user-people',
    component: () => import('@/views/pages/people/People.vue'),
    props: true,
    meta: {
      middleware: ['auth', 'verified'],
      pageTitle: "User's People",
      keepAlive: true,
    },
  },
  {
    path: '/people',
    name: 'people',
    component: () => import('@/views/pages/people/People.vue'),
    props: true,
    meta: {
      middleware: ['auth', 'verified'],
      pageTitle: 'People',
      keepAlive: true,
    },
  },
  {
    path: '/contact-lists',
    name: 'contact-lists',
    component: () => import('@/views/pages/contacts/ContactLists.vue'),
    props: true,
    meta: {
      middleware: ['auth', 'verified'],
      pageTitle: 'Contact Lists',
      keepAlive: true,
    },
  },
  {
    path: '/users/:username/profile',
    name: 'user-profile',
    props: (route) => ({
      itemId: !_.isEmpty(route.query) ? route.query.itemId : null,
    }),
    component: () => import('@/views/pages/profile/Profile.vue'),
    meta: {
      middleware: ['auth', 'verified'],
      pageTitle: "User's Profile",
      keepAlive: true,
    },
  },

  {
    path: '/profile',
    name: 'profile',
    props: (route) => ({
      itemId: !_.isEmpty(route.query) ? route.query.itemId : null,
      shareCapture: !_.isEmpty(route.query) ? route.query.shareCapture : null,
    }),
    component: () => import('@/views/pages/profile/MyProfile.vue'),
    meta: {
      middleware: ['auth', 'verified'],
      pageTitle: 'Profile',
      keepAlive: true,
    },
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('@/views/pages/agendums/Agendums.vue'),
    meta: {
      pageTitle: 'Calendar',
      middleware: ['auth', 'verified'],
      keepAlive: true,
    },
  },
  {
    path: '/users/:username/calendar',
    name: 'user-calendar',
    component: () => import('@/views/pages/agendums/Agendums.vue'),
    props: true,
    meta: {
      middleware: ['auth', 'verified'],
      pageTitle: "User's Calendar",
      breadcrumb: [
        {
          text: "User's Profile",
          to: { name: 'user-profile' },
          active: false,
        },
        {
          text: 'Calendar',
          active: true,
        },
      ],
      keepAlive: true,
    },
  },

  {
    path: '/agendums/timeline',
    name: 'agendums-timeline',
    component: () => import('@/views/pages/agendums/Agendums.vue'),
    meta: {
      pageTitle: 'Timeline',
      middleware: ['auth', 'verified'],
      keepAlive: true,
    },
  },
  {
    path: '/users/:username/agendums/timeline',
    name: 'user-agendums-timeline',
    component: () => import('@/views/pages/agendums/Agendums.vue'),
    props: true,
    meta: {
      middleware: ['auth', 'verified'],
      pageTitle: "User's Agendums",
      breadcrumb: [
        {
          text: "User's Profile",
          to: { name: 'user-profile' },
          active: false,
        },
        {
          text: 'Agendums',
          active: true,
        },
      ],
      keepAlive: true,
    },
  },

  {
    path: '/preview/agendum/',
    name: 'preview-agendum',
    component: () => import('@/views/pages/agendums/AgendumPreview.vue'),
    props: true,
    meta: {
      pageTitle: 'Agendum Preview',
      middleware: [],
      breadcrumb: [
        {
          text: "User's Profile",
          to: { name: 'user-profile' },
          active: false,
        },
        {
          text: 'Agendum Preview',
          active: true,
        },
      ],
    },
  },

  {
    path: '/agendums/list',
    name: 'agendums-list',
    component: () => import('@/views/pages/agendums/Agendums.vue'),
    meta: {
      pageTitle: 'Latest Agendums',
      middleware: ['auth', 'verified'],
      keepAlive: true,
    },
  },
  {
    path: '/users/:username/agendums/list',
    name: 'user-agendums-list',
    component: () => import('@/views/pages/agendums/Agendums.vue'),
    props: true,
    meta: {
      middleware: ['auth', 'verified'],
      pageTitle: "User's Agendums",
      breadcrumb: [
        {
          text: "User's Profile",
          to: { name: 'user-profile' },
          active: false,
        },
        {
          text: 'Agendums',
          active: true,
        },
      ],
    },
  },

  {
    path: '/agendums/invitations',
    name: 'agendum-invitations',
    props: (route) => ({
      agendumId: !_.isEmpty(route.query) ? route.query.agendumId : null,
    }),
    component: () => import('@/views/pages/agendums/Invitations.vue'),
    meta: {
      pageTitle: 'Invitations',
      middleware: ['auth', 'verified'],
      keepAlive: true,
    },
  },

  {
    path: '/agendums/:id',
    name: 'agendum-details',
    component: () => import('@/views/pages/agendums/Agendum.vue'),
    props: (route) => ({
      propJoinNow: !_.isEmpty(route.query) ? route.query.joinNow === true : null,
      propOpenMembershipSettingsModal: !_.isEmpty(route.query)
        ? route.query.openMembershipSettingsModal === true
        : null,
      propOpenMembersModalTab: !_.isEmpty(route.query)
        ? route.query.openMembersModalTab
        : null,
      propOpenInvitationsModalTab: !_.isEmpty(route.query)
        ? route.query.openInvitationsModalTab
        : null,
      propScrollToComponent: !_.isEmpty(route.query)
        ? route.query.scrollToComponent
        : null,
      propFilterAgendumId: !_.isEmpty(route.query)
        ? route.query.filterAgendumId
        : null,
      propAttachmentId: !_.isEmpty(route.query) ? route.query.attachmentId : null,
      propNoteId: !_.isEmpty(route.query) ? route.query.noteId : null,
      propAttachmentType: !_.isEmpty(route.query)
        ? route.query.attachmentType
        : null,
    }),
    meta: {
      middleware: ['auth', 'verified'],
      pageTitle: 'Agendum Details',
      keepAlive: true,
    },
  },
  {
    path: '/chat/:id',
    name: 'chat-details',
    component: () => import('@/views/pages/chat/Chat.vue'),
    meta: {
      middleware: ['auth', 'verified'],
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
      keepAlive: true,
    },
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import('@/views/pages/chat/Chat.vue'),
    meta: {
      middleware: ['auth', 'verified'],
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
      keepAlive: true,
    },
  },

  {
    path: '/auth/:provider/callback',
    name: 'social-login',
    component: () =>
      import('@/views/pages/miscellaneous/SocialMediaCallback.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/auth/:provider/callback-mobile',
    name: 'social-mobile-login',
    component: () =>
      import('@/views/pages/miscellaneous/SocialMediaCallback.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/download',
    name: 'download',
    component: () => import('@/views/pages/miscellaneous/Download.vue'),
    meta: {
      layout: 'full',
    },
  },

  {
    path: '/welcome',
    name: 'welcome',
    component: () => import('@/views/pages/authentication/Welcome.vue'),
    meta: {
      layout: 'full',
      middleware: ['guest'],
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      middleware: ['guest'],
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/pages/authentication/Register.vue'),
    meta: {
      layout: 'full',
      middleware: ['guest'],
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register-invitation',
    name: 'auth-register-invitation',
    component: () =>
      import('@/views/pages/authentication/RegisterInvitation.vue'),
    meta: {
      layout: 'full',
      middleware: ['guest'],
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      middleware: ['guest'],
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/pages/authentication/ResetPassword.vue'),
    meta: {
      layout: 'full',
      middleware: ['guest'],
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/coming-soon',
    name: 'coming-soon',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'full',
      middleware: [],
    },
  },
  {
    path: '/feature-coming-soon',
    name: 'feature-coming-soon',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      middleware: ['auth'],
    },
  },
  {
    path: '/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      middleware: ['auth'],
    },
  },
  {
    path: '/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'full',
      middleware: [],
    },
  },

  {
    path: '/no-internet-connection',
    name: 'no-internet-connection',
    component: () =>
      import('@/views/pages/miscellaneous/NoInternetConnection.vue'),
    meta: {
      layout: 'full',
      middleware: [],
    },
  },

  {
    path: '/help-center/faq',
    name: 'pages-faq',
    component: () => import('@/views/pages/help-center/Faq.vue'),
    meta: {
      pageTitle: 'FAQ',
      breadcrumb: [
        {
          text: 'FAQ',
          active: true,
        },
      ],
      layout: 'full',
      middleware: ['auth', 'verified'],
    },
  },
  {
    path: '/pricing',
    name: 'pages-pricing',
    component: () => import('@/views/pages/pricing/Pricing.vue'),
    meta: {
      middleware: ['auth', 'verified'],
    },
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    component: () => import('@/views/pages/authentication/VerifyEmail.vue'),
    meta: {
      layout: 'full',
      middleware: ['auth', 'unverified'],
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      action: 'read',
      middleware: [],
    },
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      action: 'read',
      middleware: ['auth', 'verified'],
    },
  },
  {
    path: '/error',
    name: 'misc-error',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'full',
      middleware: [],
    },
  },
]
