import store from '@/store'

const defaultEndpoint = '/agendum-attachments'

const state = {
  poll: {},
  photo: {},
  file: {},
  photoUpdateCounter: 0,
}

const getters = {
  polls: (state) => (agendumId) => {
    return state.poll[agendumId] ? state.poll[agendumId] : null
  },

  photos: (state) => (agendumId) => {
    return state.photo[agendumId] ? state.photo[agendumId] : null
  },

  files: (state) => (agendumId) => {
    return state.file[agendumId] ? state.file[agendumId] : null
  },

  photoUpdateCounter: (state) => {
    return state.photoUpdateCounter
  },
}

const actions = {
  async resetState({ dispatch, commit }, exceptId = null) {
    commit('init', exceptId)
  },

  async fetchAttachments({ commit }, data) {
    let { payload } = data
    let endpoint = `${defaultEndpoint}`

    let response = await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })

    if (response.data == null || response.data.data == null) {
      commit('setAttachments', {
        data: null,
        agendumId: payload.agendum_id,
        type: payload.type,
      })
      return response
    }

    commit('setAttachments', {
      data: response.data,
      agendumId: payload.agendum_id,
      type: payload.type,
    })

    return response
  },

  async fetchAttachment({ commit }, data) {
    let { payload, id } = data
    let endpoint = `${defaultEndpoint}/${id}`

    return await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })
  },

  async fetchReactions({ commit }, data) {
    let { payload, id } = data
    let endpoint = `${defaultEndpoint}/${id}/reactions`

    return await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })
  },

  async fetchVotes({ commit }, data) {
    let { payload, id } = data
    let endpoint = `${defaultEndpoint}/option-votes`

    return await this._vm.$api
      .get(endpoint, { params: payload })
      .catch((error) => {
        return { data: null }
      })
  },

  async createPresignedUploadURL({ dispatch, commit }, form) {
    let { agendum_id, type } = form
    let endpoint = `${defaultEndpoint}/presigned-upload-url`

    return await form.post(endpoint)
  },

  async createAttachment({ dispatch, commit }, form) {
    let { agendum_id, type } = form

    await form.post(defaultEndpoint)

    let response = form._response

    if (!form.errors.any() && response && response.data) {
      commit('setAttachment', {
        attachment: response.data,
        attachmentId: response.data.id,
        agendumId: agendum_id,
        type: type,
      })
    }

    return response
  },

  async updateAttachment({ dispatch, commit }, form) {
    let { agendum_id, id, type } = form

    await form.post(`${defaultEndpoint}/${id}`)
    let response = form._response

    if (!form.errors.any() && response && response.data) {
      commit('setAttachment', {
        attachment: response.data,
        attachmentId: response.data.id,
        agendumId: agendum_id,
        type: type,
      })
    }

    return response
  },

  async updateAttachmentSettings({ dispatch, commit }, form) {
    let { agendum_id, attachment_id, type } = form

    await form.post(`${defaultEndpoint}/${attachment_id}/settings`)
    let response = form._response

    if (!form.errors.any() && response && response.data) {
      commit('setAttachment', {
        attachment: response.data,
        attachmentId: response.data.id,
        agendumId: agendum_id,
        type: type,
      })
    }

    return response
  },

  async makeAttachmentReaction({ dispatch, commit }, form) {
    let { attachment_id } = form

    return form.post(`${defaultEndpoint}/${attachment_id}/make-reaction`)
  },

  async deleteAttachment({ dispatch, commit }, form) {
    let { agendum_id, attachment_id, type } = form

    delete form.attachment_id
    if (form.checked) {
      delete form.checked
    }
    if (form.option_id) {
      delete form.option_id
    }

    await form.delete(`${defaultEndpoint}/${attachment_id}`)
    let response = form._response

    if (!form.errors.any() && response && response.status) {
      commit('setAttachment', {
        attachment: null,
        attachmentId: attachment_id,
        agendumId: agendum_id,
        type: type,
      })
    }

    return response
  },

  /** @deprecated */
  async createPollOption({ dispatch, commit }, form) {
    let { agendum_id, poll_id } = form

    await form.post(`${defaultEndpoint}/poll-options/`, true)

    let response = form._response

    if (!form.errors.any() && response && response.data) {
      commit('setPollOption', {
        data: response.data,
        pollOptionId: response.data.id,
        pollId: poll_id,
        agendumId: agendum_id,
      })
    }

    return response
  },

  /** @deprecated */
  async deletePollOption({ dispatch, commit }, form) {
    let { agendum_id, id, poll_id } = form

    await form.delete(`${defaultEndpoint}/poll-options/${id}`)
    let response = form._response

    if (!form.errors.any() && response && response.status) {
      commit('setPollOption', {
        data: null,
        pollOptionId: id,
        pollId: poll_id,
        agendumId: agendum_id,
      })
    }

    return response
  },

  voteOption({ dispatch, commit }, form) {
    return form.post(`${defaultEndpoint}/vote`, true)
  },

  checkAttachmentsActivity({ dispatch, commit }, event) {
    if (process.env.VUE_APP_WEBSOCKET_ENABLED === 'true') {
      if (event.updateEntityStore) {
        if (event.action === 'created' || event.action === 'updated') {
          commit('setAttachment', {
            attachment: event.data,
            attachmentId: event.data.id,
            agendumId: event.data.agendum_id,
            type: event.data.attributes.type,
          })
        } else if (event.action === 'deleted') {
          commit('setAttachment', {
            attachment: null,
            attachmentId: event.data.id,
            agendumId: event.data.agendum_id,
            type: event.data.attachment_type,
          })
        } else if (event.action === 'voted') {
          commit('setVote', {
            agendumId: event.data.agendum_id,
            pollId: event.data.poll_id,
            pollOptionId: event.data.poll_option_id,
            data: event.data,
            operation: event.data.operation,
          })
        }
      }

      if (!_.isEmpty(event.activity)) {
        store.commit('agendumActivities/setActivity', {
          activity: !_.isEmpty(event.activity.attributes)
            ? event.activity
            : null,
          activityId: event.activity.id,
          agendumId: event.data.agendum_id,
        })
      }
    }
  },
}

const mutations = {
  setAttachments(state, payload) {
    let { data, agendumId, type } = payload

    let newData = {
      data: !_.isEmpty(data) && data.data ? data.data : null,
      nextPageLink: !_.isEmpty(data) && data.links ? data.links.next : null,
    }

    let attachments = { ...state[type] }
    attachments[agendumId] = newData

    state[type] = attachments
  },

  setAttachment(state, data) {
    let keyToUpdate = null
    let { attachmentId, attachment, agendumId, type } = data
    let attachments = { ...state[type] }

    if (
      _.isEmpty(attachments[agendumId]) ||
      _.isEmpty(attachments[agendumId].data)
    ) {
      if (attachment == null) return

      attachments[agendumId] = {
        data: [attachment],
        nextPageLink: null,
      }

      state[type] = attachments
      return
    }

    for (const key in attachments[agendumId].data) {
      if (
        attachments[agendumId].data[key] &&
        attachments[agendumId].data[key].id === attachmentId
      ) {
        keyToUpdate = key
        break
      }
    }

    if (attachment != null) {
      if (
        keyToUpdate == null &&
        attachments[agendumId].data[0].attributes.created_at_timestamp <=
          attachment.attributes.created_at_timestamp
      ) {
        attachments[agendumId].data = [
          attachment,
          ...attachments[agendumId].data,
        ]
      } else if (keyToUpdate != null) {
        attachments[agendumId].data[keyToUpdate].attributes = {
          ...attachment.attributes,
        }

        if (attachment.relations != null && attachment.relations.options) {
          attachments[agendumId].data[keyToUpdate].relations.options = [
            ...attachment.relations.options,
          ]
        }

        state.photoUpdateCounter++
      }
    } else if (attachment == null && keyToUpdate != null) {
      attachments[agendumId].data.splice(keyToUpdate, 1)
    }
  },

  /** @deprecated */
  setPollOption(state, payload) {
    let { data, agendumId, pollId, pollOptionId } = payload

    let polls = { ...state.poll[agendumId] }
    let poll = null
    let pollKey = null
    let pollOptionKey = null

    for (const key in polls.data) {
      if (polls.data[key] && polls.data[key].id === pollId) {
        pollKey = key
        poll = polls.data[key]
        break
      }
    }

    if (poll == null) return

    if (_.isEmpty(state.poll[agendumId].data[pollKey].relations.options)) {
      if (data == null) return

      state.poll[agendumId].data[pollKey].relations.options = [data]
      return
    }

    for (const key in poll.relations.options) {
      if (
        poll.relations.options[key] &&
        poll.relations.options[key].id === pollOptionId
      ) {
        pollOptionKey = key
        break
      }
    }

    if (data != null && pollOptionKey == null) {
      state.poll[agendumId].data[pollKey].relations.options = [
        ...poll.relations.options,
        data,
      ]
    } else if (data == null && pollOptionKey != null) {
      state.poll[agendumId].data[pollKey].relations.options.splice(
        pollOptionKey,
        1
      )
    }
  },

  setVote(state, payload) {
    let { agendumId, pollId, pollOptionId, data, operation } = payload

    console.log('data', data)

    let polls = { ...state.poll[agendumId] }
    let poll = null

    let pollKey = null
    let pollOptionKey = null

    for (const key in polls.data) {
      if (polls.data[key] && polls.data[key].id === pollId) {
        pollKey = key
        poll = polls.data[key]
        break
      }
    }

    if (poll == null) return

    for (const key in poll.relations.options) {
      if (
        poll.relations.options[key] &&
        poll.relations.options[key].id === pollOptionId && poll.relations.options[key].attributes.fetched_at_timestamp <= data.fetched_at_timestamp
      ) {
        pollOptionKey = key
        break
      }
    }

    if (!pollOptionKey) return

    let stats =
      state.poll[agendumId].data[pollKey].relations.options[pollOptionKey]
        .relations.stats

    console.log('stats', stats)

    stats.votes_count = data.votes_count
    stats.positive_votes_count = data.positive_votes_count
    state.poll[agendumId].data[pollKey].relations.options[pollOptionKey].attributes.fetched_at_timestamp = data.fetched_at_timestamp
  },

  init(state, exceptId) {
    let savedAttachmentState = null

    if (exceptId) {
      savedAttachmentState =
        state.attachments && !_.isEmpty(state.attachments[exceptId])
          ? state.attachments[exceptId]
          : null
    }

    state.attachments = {}

    if (savedAttachmentState) {
      state.attachments[exceptId] = savedAttachmentState
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
